import * as React from "react"
import Layout from "../components/Layout";
import { graphql, useStaticQuery } from "gatsby";
import Hero from "../components/Hero";
import Description from "../components/Description";
import * as styles from "../components/AdditionalInfo/styles.module.scss";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      indexJson {
        pageHero {
          title
          dek
          gallery {
            id
            video
            image {
              childImageSharp {
                fluid(maxWidth: 2000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        content {
          paragraph
        }
      }
    }
  `);

  const pageData = data.indexJson;
  
  return (
    <main>
      <title>{pageData.pageHero.title}</title>
      <head>
        <link rel="shortcut icon" href="https://www.telemundo.com/sites/nbcutelemundo/themes/aurora_tlmd/favicon.ico"/>
      </head>
      <Layout false>
        <Hero
          title={pageData.pageHero.title}
          gallery={pageData.pageHero.gallery}
        />
        <div style={{'margin-top': '70px'}}>
        </div>
        <Description bodyText={pageData.content.paragraph}></Description>        
      </Layout>
    </main>
  );
};

export default IndexPage;

